<template>
  <b-modal
    id="modal-level"
    v-model="show"
    title="Thêm cấp độ mới"
    size="md"
    centered
    hide-footer
    @hide="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <basic-input
          required
          label="Mã Cấp độ"
          placeholder="Nhập mã cấp độ"
          :value.sync="form.code"
          name="code"
          :state="validateState('code')"
          :invalidFeedback="errors.first('code')"
          v-validate="{
            required: true,
            alpha_num: true,
            max: 50,
          }"
          data-vv-as="Mã cấp độ"
          maxlength="50"
        />
        <basic-input
          required
          label="Thêm cấp độ"
          placeholder="Nhập cấp độ"
          :value.sync="form.name"
          name="name"
          :state="validateState('name')"
          :invalidFeedback="errors.first('name')"
          v-validate="{
            required: true,
          }"
          data-vv-as="Cấp độ"
          disabled
        />
        <div class="d-flex justify-content-end align-items-center">
          <b-button class="btn" type="button" @click="resetModal">
            Huỷ
          </b-button>
          <b-button class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { SET_MODAL } from '@/core/services/store/context.module';
const { mapState, mapMutations } = createNamespacedHelpers('context');
export default {
  name: 'ModalLevel',
  data() {
    return {
      form: {
        code: null,
        name: null,
        order: null,
      },
      loading: false,
    };
  },
  computed: {
    ...mapState(['modalShow']),
    show: {
      get() {
        return this.modalShow;
      },
      set(val) {
        this.SET_MODAL(val);
      },
    },
  },
  created() {
    this.getLevelLast();
  },
  methods: {
    ...mapMutations({ SET_MODAL }),
    async getLevelLast() {
      try {
        this.loading = true;
        const { meta, data } = await this.$api.get(
          '/LessonLevel/GenerateOrder',
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: meta.message,
          });
        }
        this.form.name = `${data}`;
        this.form.order = Number(data);
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    submit() {
      const params = {
        code: this.form.code.trim(),
        name: this.form.name,
        order: this.form.order,
      };
      this.createLevel(params);
    },
    resetModal() {
      this.form = {
        code: null,
        name: null,
      };
      this.$emit('reset');
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit();
        }
        return;
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    async createLevel(params) {
      try {
        this.loading = true;
        const { meta, error } = await this.$api.post('/LessonLevel', params);
        if (!meta.success) {
          this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
          return;
        }
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Thêm mới thành công',
        });
        this.$emit('submit');
        this.resetModal();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang=""></style>
