var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-level","title":"Thêm cấp độ mới","size":"md","centered":"","hide-footer":""},on:{"hide":_vm.resetModal},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleValidate.apply(null, arguments)}}},[_c('basic-input',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: true,
          alpha_num: true,
          max: 50,
        }),expression:"{\n          required: true,\n          alpha_num: true,\n          max: 50,\n        }"}],attrs:{"required":"","label":"Mã Cấp độ","placeholder":"Nhập mã cấp độ","value":_vm.form.code,"name":"code","state":_vm.validateState('code'),"invalidFeedback":_vm.errors.first('code'),"data-vv-as":"Mã cấp độ","maxlength":"50"},on:{"update:value":function($event){return _vm.$set(_vm.form, "code", $event)}}}),_c('basic-input',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: true,
        }),expression:"{\n          required: true,\n        }"}],attrs:{"required":"","label":"Thêm cấp độ","placeholder":"Nhập cấp độ","value":_vm.form.name,"name":"name","state":_vm.validateState('name'),"invalidFeedback":_vm.errors.first('name'),"data-vv-as":"Cấp độ","disabled":""},on:{"update:value":function($event){return _vm.$set(_vm.form, "name", $event)}}}),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":_vm.resetModal}},[_vm._v(" Huỷ ")]),_c('b-button',{staticClass:"btn btn-success ml-3",attrs:{"type":"submit"}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Neolex/Basic/save.svg"}})],1),_vm._v(" Lưu ")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }